<template>
  <Card p="p-0 p-md-6">
    <div class="lg:grid grid-cols-3 mt-6">
      <p class="font-semibold text-lightPurple-2 mb-2">Change password</p>
      <!-- <svg width="11" height="7" fill="none" class="transition-all direction">
        <path d="M.842 1l4.5 4.5 4.5-4.5" stroke="#000" />
      </svg> -->
      <Button outline @click="showModal = true" text="Change password" />
    </div>

    <Modal
      :display="showModal"
      @close="showModal = false"
      title="Change password"
    >
      <div class="form">
        <!-- <h4 class="text-lg font-bold text-black">Change Password</h4> -->
        <Input
          type="password"
          placeholder="Current password"
          class="mt-8"
          width="w-full"
          id="password"
          :revealPassword="true"
          v-model="password.currentPassword"
          :validation="rules.password"
          errorText="Current password cannot be empty"
          @valid="valid.password = $event"
        />
        <Input
          type="password"
          placeholder="New password"
          class="mt-8"
          width="w-full"
          id="new password"
          :revealPassword="true"
          v-model="password.newPassword"
          errorText="Password must have at least 8 characters, a lowercase letter, an uppercase letter, a number and a special character"
          :validation="rules.newPassword"
          @valid="valid.new_password = $event"
        />
        <Input
          type="password"
          placeholder="Confirm new password"
          :revealPassword="true"
          class="mt-8"
          errorText="Passwords do not match"
          v-model="confirm_password"
          width="w-full"
          id="confirm password"
          :validation="rules.confirmPassword"
          @valid="valid.confirm_password = $event"
        />
        <Button
          text="Change Password"
          width="w-full"
          class="my-12"
          :shadow="true"
          @click="updatePassword()"
          :loading="loading"
          :disabled="disableButton"
        />
      </div>
    </Modal>
  </Card>
</template>

<script>
  import { mapActions } from "vuex";
  import { Input } from "@/UI/Input";
  import { Button } from "@/UI/Button";
  import { Modal } from "@/UI/Modals";
  // import Modal from "@/UI/Modal";
  export default {
    components: {
      Input,
      Button,
      Modal,
    },
    data: () => ({
      showModal: false,
      password: {
        currentPassword: "",
        newPassword: "",
      },
      loading: false,
      confirm_password: "",
      valid: {
        password: false,
        confirm_password: false,
        new_password: false,
      },
    }),
    computed: {
      rules() {
        return {
          password: this.password.currentPassword.length > 0,
          newPassword:
            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/.test(
              this.password.newPassword
            ),
          confirmPassword: this.password.newPassword === this.confirm_password,
        };
      },
      disableButton() {
        return Object.values(this.rules).includes(false);
      },
    },
    methods: {
      ...mapActions("notification", ["showAlert"]),
      ...mapActions("auth", ["changePassword"]),
      // ...mapActions("notification", ["showAlert"]),

      updatePassword() {
        if (Object.values(this.valid).includes(false)) {
          this.showAlert({
            description: "Please fill all inputs correctly",
            display: true,
            type: "error",
            title: "",
          });
          return false;
        } else {
          this.loading = true;
          this.changePassword(this.password)
            .then(() => {
              this.loading = false;
              this.showModal = false;
              this.showAlert({
                display: true,
                type: "success",
                description: `Your password has been changed successfully`,
              });
              this.showModal = false;
            })
            .catch(() => {
              this.loading = false;
            });
        }
      },
    },
  };
</script>

<style scoped>
  .direction {
    transform: rotate(-90deg);
  }
</style>
