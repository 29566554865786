var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Card",
    { attrs: { p: "p-0 p-md-6" } },
    [
      _c(
        "div",
        { staticClass: "lg:grid grid-cols-3 mt-6" },
        [
          _c("p", { staticClass: "font-semibold text-lightPurple-2 mb-2" }, [
            _vm._v("Change password"),
          ]),
          _c("Button", {
            attrs: { outline: "", text: "Change password" },
            on: {
              click: function ($event) {
                _vm.showModal = true
              },
            },
          }),
        ],
        1
      ),
      _c(
        "Modal",
        {
          attrs: { display: _vm.showModal, title: "Change password" },
          on: {
            close: function ($event) {
              _vm.showModal = false
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "form" },
            [
              _c("Input", {
                staticClass: "mt-8",
                attrs: {
                  type: "password",
                  placeholder: "Current password",
                  width: "w-full",
                  id: "password",
                  revealPassword: true,
                  validation: _vm.rules.password,
                  errorText: "Current password cannot be empty",
                },
                on: {
                  valid: function ($event) {
                    _vm.valid.password = $event
                  },
                },
                model: {
                  value: _vm.password.currentPassword,
                  callback: function ($$v) {
                    _vm.$set(_vm.password, "currentPassword", $$v)
                  },
                  expression: "password.currentPassword",
                },
              }),
              _c("Input", {
                staticClass: "mt-8",
                attrs: {
                  type: "password",
                  placeholder: "New password",
                  width: "w-full",
                  id: "new password",
                  revealPassword: true,
                  errorText:
                    "Password must have at least 8 characters, a lowercase letter, an uppercase letter, a number and a special character",
                  validation: _vm.rules.newPassword,
                },
                on: {
                  valid: function ($event) {
                    _vm.valid.new_password = $event
                  },
                },
                model: {
                  value: _vm.password.newPassword,
                  callback: function ($$v) {
                    _vm.$set(_vm.password, "newPassword", $$v)
                  },
                  expression: "password.newPassword",
                },
              }),
              _c("Input", {
                staticClass: "mt-8",
                attrs: {
                  type: "password",
                  placeholder: "Confirm new password",
                  revealPassword: true,
                  errorText: "Passwords do not match",
                  width: "w-full",
                  id: "confirm password",
                  validation: _vm.rules.confirmPassword,
                },
                on: {
                  valid: function ($event) {
                    _vm.valid.confirm_password = $event
                  },
                },
                model: {
                  value: _vm.confirm_password,
                  callback: function ($$v) {
                    _vm.confirm_password = $$v
                  },
                  expression: "confirm_password",
                },
              }),
              _c("Button", {
                staticClass: "my-12",
                attrs: {
                  text: "Change Password",
                  width: "w-full",
                  shadow: true,
                  loading: _vm.loading,
                  disabled: _vm.disableButton,
                },
                on: {
                  click: function ($event) {
                    return _vm.updatePassword()
                  },
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }